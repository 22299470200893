import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import axios from "axios"
import { TopHeader } from "../components/TopHeader"
import { Form, TextArea, Button, Message } from "semantic-ui-react"
import { rhythm } from "../utils/typography"
import "./custom.css"

class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      message: "",
      sendSuccess: null,
      isSending: false,
    }
    this.sendMessage = this.sendMessage.bind(this)
    this.disableSubmit = this.disableSubmit.bind(this)
  }

  disableSubmit() {
    const { isSending, name, email, message } = this.state
    return (
      isSending ||
      name.trim() === "" ||
      email.trim() === "" ||
      message.trim() === ""
    )
  }

  async sendMessage(e) {
    e.preventDefault()
    if (this.state.isSending) {
      return
    }
    try {
      await axios.post(process.env.GATSBY_API_URL + "/contact-form", this.state)
      this.setState({
        isSending: false,
        sendSuccess: true,
        name: "",
        email: "",
        message: "",
      })
    } catch (err) {
      this.setState({ isSending: false, sendSuccess: false })
      console.log(err)
    }
  }

  render() {
    const siteTitle = this.props.data.site.siteMetadata.title

    return (
      <>
        <TopHeader />
        <Layout location={this.props.location} title={siteTitle}>
          <div
            style={{
              maxWidth: rhythm(20),
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <h1 className="main-title">Contact</h1>
            <SEO title="Contact" />
            <p>
              If you have questions or comments about the Declaration, contact
              us using the form below and we'll be sure to get back to you.
            </p>
            <Form>
              <Form.Field>
                <label htmlFor="name">Name*</label>
                <input
                  placeholder="Name"
                  id="name"
                  value={this.state.name}
                  onChange={e => this.setState({ name: e.target.value })}
                  required
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="email">Email Address*</label>
                <input
                  placeholder="you@example.com"
                  id="email"
                  value={this.state.email}
                  onChange={e => this.setState({ email: e.target.value })}
                  required
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="message">Message*</label>
                <TextArea
                  id="message"
                  value={this.state.message}
                  onChange={e => this.setState({ message: e.target.value })}
                  rows={10}
                  required
                ></TextArea>
              </Form.Field>
              {this.state.sendSuccess !== null && (
                <Message
                  positive={this.state.sendSuccess}
                  negative={!this.state.sendSuccess}
                >
                  <Message.Header>
                    {this.state.sendSuccess ? "Message sent!" : "Uh-oh"}
                  </Message.Header>
                  <p>
                    {this.state.sendSuccess
                      ? "Your message has been send! We'll be in touch shorty."
                      : "There was an error adding sending your message. Please try again."}
                  </p>
                </Message>
              )}
              <Button
                onClick={this.sendMessage}
                disabled={this.disableSubmit()}
              >
                {this.state.isSending ? "Sending..." : "Send Message"}
              </Button>
            </Form>
          </div>
        </Layout>
      </>
    )
  }
}

export default Contact

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
